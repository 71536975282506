<template>
    <div class="flex items-center justify-center min-h-screen bg-gray-100">
        <div class="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
            <img src="@/assets/logo.svg" alt="Company Logo" class="mx-auto w-32 mb-4" />
            <h1 class="text-2xl font-bold text-center">RabbitMQ DLX Manager</h1>
            <form @submit.prevent="handleSubmit" class="space-y-4">
                <div>
                    <label class="block text-sm font-medium text-gray-700">Environment:</label>
                    <div class="flex space-x-2">
                        <button type="button" :class="{ 'bg-blue-500 text-white': environment === 'dev' }"
                            @click="environment = 'dev'" class="px-3 py-1 border border-gray-300 rounded">Dev</button>
                        <button type="button" :class="{ 'bg-blue-500 text-white': environment === 'staging' }"
                            @click="environment = 'staging'"
                            class="px-3 py-1 border border-gray-300 rounded">Staging</button>
                        <button type="button" :class="{ 'bg-blue-500 text-white': environment === 'prod' }"
                            @click="environment = 'prod'" class="px-3 py-1 border border-gray-300 rounded">Prod</button>
                    </div>
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Microservice:</label>
                    <div class="grid grid-cols-2 gap-2">
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'assets' }"
                            @click="microservice = 'assets'" class="px-3 py-1 border border-gray-300 rounded">📦
                            Assets</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'finance' }"
                            @click="microservice = 'finance'" class="px-3 py-1 border border-gray-300 rounded">💰
                            Finance</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'invoice' }"
                            @click="microservice = 'invoice'" class="px-3 py-1 border border-gray-300 rounded">💸
                            Invoice</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'notifications' }"
                            @click="microservice = 'notifications'" class="px-3 py-1 border border-gray-300 rounded">🔔
                            Notifications</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'online-booking' }"
                            @click="microservice = 'online-booking'" class="px-3 py-1 border border-gray-300 rounded">📅
                            Online Booking</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'sales' }"
                            @click="microservice = 'sales'" class="px-3 py-1 border border-gray-300 rounded">🛒
                            Sales</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'tracking' }"
                            @click="microservice = 'tracking'" class="px-3 py-1 border border-gray-300 rounded">📍
                            Tracking</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'tutorials' }"
                            @click="microservice = 'tutorials'" class="px-3 py-1 border border-gray-300 rounded">🎓
                            Tutorials</button>
                        <button type="button" :class="{ 'bg-blue-100': microservice === 'user' }"
                            @click="microservice = 'user'" class="px-3 py-1 border border-gray-300 rounded">👤
                            User</button>
                    </div>
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Queue (without _dlx):</label>
                    <input type="text" v-model="queue" required
                        class="block w-full mt-1 p-2 border border-gray-300 rounded" />
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Messages to Retry:</label>
                    <div class="flex items-center">
                        <input type="number" v-model="messagesToRetry" :disabled="retryAllMessages" min="1"
                            class="block w-full mt-1 p-2 border border-gray-300 rounded" />
                        <label class="ml-2 text-sm font-medium text-gray-700">
                            <input type="checkbox" v-model="retryAllMessages" class="mr-1" />
                            Retry All
                        </label>
                    </div>
                </div>
                <div>
                    <label class="block text-sm font-medium text-gray-700">Secret API Key:</label>
                    <input type="text" v-model="secretApiKey" required
                        class="block w-full mt-1 p-2 border border-gray-300 rounded" />
                </div>
                <button type="submit"
                    class="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700">
                    Submit
                </button>
            </form>
        </div>
        <ApiNotification ref="notification" />
    </div>
</template>

<script>
import axios from 'axios';
import ApiNotification from './ApiNotification.vue';

export default {
    components: {
        ApiNotification,
    },
    data() {
        return {
            environment: 'dev',
            microservice: '',
            queue: '',
            messagesToRetry: 1,
            retryAllMessages: false,
            secretApiKey: '',
        };
    },
    methods: {
        async handleSubmit() {
            const baseUrl = {
                dev: 'https://dev-api.taclia.com',
                staging: 'https://staging-api.taclia.com',
                prod: 'https://api.taclia.com',
            };
            const url = `${baseUrl[this.environment]}/${this.microservice}/consume-dlx/${this.queue}`;

            const params = {};
            if (!this.retryAllMessages) {
                params.messagesToRetry = this.messagesToRetry;
            }

            try {
                await axios.post(url, null, {
                    params,
                    headers: {
                        'secret-api-key': this.secretApiKey,
                    },
                });
                this.$refs.notification.show('Request Successful', 'success');
            } catch (error) {
                this.$refs.notification.show(`Error ${error.message}`, 'error');
            }
        },
    },
};
</script>